import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from "react-scroll";
import Button from "@mui/material/Button"
import { useEffect, useState, useRef } from 'react';
import { useBetween } from 'use-between';
import React from 'react';
import { SpotifyAuth, Scopes } from 'react-spotify-auth'
import 'react-spotify-auth/dist/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import Cookies from 'js-cookie';
import { SpotifyApiContext, Album, Artist, User } from 'react-spotify-api'
import 'react-spotify-auth/dist/index.css'
import axios from 'axios';
import { hasPointerEvents } from '@testing-library/user-event/dist/utils';
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { PerspectiveCamera } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGLTF, useAnimations  } from '@react-three/drei'
import { AnimationMixer, MeshStandardMaterial } from 'three';
import lilcube from "./lilcube.glb"
import acoustic_guitar from "./acoustic_guitar.glb"
import fender_telecaster from "./fender_telecaster.glb"
import gibson_les from "./gibson_les.glb"
import midi_keyboard from "./midi_keyboard.glb"
import record_player from "./record_player.glb"
import balloon from "./balloon.glb"
import lava_lamp from "./lava_lamp.glb"
import vinyl_base from "./vinyl_base.glb"
import vinyl_top from "./vinyl_top.glb"
import vinyl_record from "./vinyl_record.glb"
import heart_wall from "./heart_wall.glb"

import indie_poster from "./assets/indie_poster.png"


import aquarium from "./aquarium.glb"
import { Stats, OrbitControls } from '@react-three/drei'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import teste from './assets/test.jpg'
import teste2 from './assets/test.jpg'

const Balloon = () => {
  const boxRef = useRef();
  const b = useLoader(GLTFLoader, balloon)

b.scene.children.forEach((mesh, i) => {
mesh.castShadow = true;
})

  b.castShadow = true;
  b.scene.castShadow = true;
  useFrame(() => {
    boxRef.current.rotation.y += 0.005;
  });


  return (
    <primitive 
    castShadow
    object={b.scene} 
    ref={boxRef}
    scale={[1.0, 1.0,1.0]}
    position={[-16, 1 , 0]} 
    rotation-x={Math.PI * 0.0} 
    rotation-y={Math.PI * 0.0}
  
    >
      <boxBufferGeometry args={[2, 2, 2]} />
      <meshStandardMaterial />
    </primitive>
    



  );
}


const VinylBase = () => {
  const boxRef = useRef();
  const v_b = useLoader(GLTFLoader, vinyl_base)
 

  useFrame(() => {
    boxRef.current.rotation.y -= 0.01;
  });


  return (
    <primitive 
    object={v_b.scene} 
    ref={boxRef} 
    rotation-x={Math.PI * 0.0} 
    rotation-y={Math.PI * 0.0}
    scale={[1.2, 1.2,1.2]}
    position={[-0.96, -5.3 , -0.280]}
    >
      <boxBufferGeometry args={[2, 2, 2]} />
      <meshStandardMaterial />
    </primitive>
  );
}

const VinylTop = () =>{
 
  const boxRef2 = useRef();
  const v_t = useLoader(GLTFLoader, vinyl_top)


  useFrame(() => {
    boxRef2.current.rotation.y -= 0.01;
  });
  return (
<primitive
object={v_t.scene}
ref={boxRef2} 
rotation-x={Math.PI * 0.0} 
rotation-y={Math.PI * 0.0}
scale={[1.2, 1.2,1.2]}
position={[-0.96, -5.279 , -0.280]}
>
  <boxBufferGeometry args={[2, 2, 2]} />
  <meshStandardMaterial />
</primitive>

  )
}



const LavaLamp = () => {
  const group = useRef();

  const { scene, animations } = useGLTF(lava_lamp, true);
  const { actions, mixer } = useAnimations(animations, group);


  useEffect(() => {

    actions.Animation.play();
  
  }, [mixer]);


  return (
    <primitive 
    object={scene} 
   ref={group}
    rotation-x={Math.PI * 0.0} 
    rotation-y={Math.PI * 0.0}
    scale={[6.0, 6.0,7.0]}
    position={[-16.5, -2.8 , 9]}
    >
      <boxBufferGeometry args={[2, 2, 2]} />
      <meshStandardMaterial />
    </primitive>
  );
}


const VinylRecord = () => {
  const boxRef = useRef();
  const v_r = useLoader(GLTFLoader, vinyl_record)
 

  useFrame(() => {
    boxRef.current.rotation.y -= 0.03;
  });


  return (
    <primitive 
    object={v_r.scene} 
 ref={boxRef}
rotation={[0, 0 ,0]}
    scale={[2.5,2.5,2.5]}
    position={[-0.95, -5.3 ,-0.29]}
    >
      <boxBufferGeometry args={[2, 2, 2]} />
      <meshStandardMaterial />
    </primitive>
  );
}



const RecordPlayer = () => {
  const group = useRef()
  const { scene, animations } = useGLTF(record_player, true);
  const { actions, mixer } = useAnimations(animations, group);


  useEffect(() => {
    actions.Sphere.play();

  
  });


  return (
    <primitive 
    object={scene} 
   ref={group}
    scale={[1.2, 1.2,1.2]}
          position={[-1, -7, -0.3]}
          rotation={[0., 4.7,0.0]}
    >
      <boxBufferGeometry args={[2, 2, 2]} />
      <meshStandardMaterial />
    </primitive>
  );
}



const Room = () => {



  const [state_one, setState_one] = React.useState(-17);
  const [state_two, setState_two] = React.useState(-17);
  const [state_three, setState_three] = React.useState(-17);
  const [state_four, setState_four] = React.useState(-17);


  const [token, setToken] = React.useState(Cookies.get("spotifyAuthToken"))
  const [hasToken, setHasToken] = React.useState(false)
  const [hasArtists, setHasArtists] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [topArtist1, setTopArtist1] = React.useState({})
  const [topArtist2, setTopArtist2] = React.useState({})
  const [topArtist3, setTopArtist3] = React.useState({})
  const [poster,setPoster] = React.useState(teste)


  const [yearAvg, setYearAvg] = React.useState(2000)

const[era, setEra] = React.useState("Regular Listener")
const[eraDescription, setEraDescription] = React.useState("Regular Description")

const [genre, setGenre] = React.useState("MusicalGenre")
const [genreDescription, setGenreDescription] = React.useState("GenreDescription")

const [followers, setFollowers] = React.useState("Followers")
const [followersDescription, setFollowersDescription] = React.useState("FollowersDescription")

const [roomColor, setRoomColor] = React.useState("white")

const [tele, setTele] = React.useState(false)
const [midi, setMidi] = React.useState(false)
const [acoustic, setAcoustic] = React.useState(false)
const [electric, setElectric] = React.useState(false)


  const [topArtist1Src, setTopArtist1Src] = React.useState(teste)
  const [topArtist2Src, setTopArtist2Src] = React.useState(teste)
  const [topArtist3Src, setTopArtist3Src] = React.useState({})

  const [topAlbum1Src, setTopAlbum1Src] = React.useState(teste)
  const [topAlbum2Src, setTopAlbum2Src] = React.useState(teste)
 const [topAlbum3Src, setTopAlbum3Src] = React.useState(teste)
 const [topAlbum4Src, setTopAlbum4Src] = React.useState(teste) 

  const [pic, setPic] = React.useState({})

const[animation, setAnimation] = React.useState("itemPic rounded")

  const gltf = useLoader(GLTFLoader, lilcube)
  const a_g = useLoader(GLTFLoader, acoustic_guitar)
  const f_t = useLoader(GLTFLoader, fender_telecaster)
  const g_l = useLoader(GLTFLoader, gibson_les)
  const m_k = useLoader(GLTFLoader, midi_keyboard)
  const r_p = useLoader(GLTFLoader, record_player)
  const b = useLoader(GLTFLoader, balloon)
 const l_l = useLoader(GLTFLoader, lava_lamp)
const h_w = useLoader(GLTFLoader, heart_wall)

  const aq = useLoader(GLTFLoader, aquarium)


  const colorMap = useLoader(TextureLoader, topArtist1Src)
  const colorMap2 = useLoader(TextureLoader, topArtist2Src)

  const colorMap2_1 = useLoader(TextureLoader, topAlbum4Src)
  const colorMap3 = useLoader(TextureLoader, topAlbum3Src)
  const colorMap4 = useLoader(TextureLoader, topAlbum2Src)
  const colorMap5 = useLoader(TextureLoader, topAlbum1Src)


  const currentPoster = useLoader(TextureLoader, poster)
  useEffect(() => {
    axios
    .get(
        'https://api.spotify.com/v1/me', {
            params: { limit: 50, offset: 0 },
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {

             setUser(response.data.display_name)
             setPic(response.data.images[0].url)
             setHasToken(true)
        }
        )

        axios
        .get(
            'https://api.spotify.com/v1/me/top/artists', {
                params: { limit: 10, offset: 0 },
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
          
const topGenres = response.data.items[0].genres + ", " + response.data.items[1].genres + ", " + response.data.items[2].genres + ", " + response.data.items[3].genres + ", " + response.data.items[4].genres + ", " + response.data.items[5].genres + ", " + response.data.items[6].genres;
             console.log(topGenres)
const newthing = topGenres.split(',').join(", ")
             
             var funk = (newthing.match(/funk/g) || []).length
             var indie = (newthing.match(/indie/g) || []).length
             var pop = (newthing.match(/pop/g) || []).length
             var rock = (newthing.match(/rock/g) || []).length
             var metal = (newthing.match(/metal/g) || []).length
             var country = (newthing.match(/country/g) || []).length
             var jazz = (newthing.match(/jazz/g) || []).length
             var samba = (newthing.match(/samba/g) || []).length
             var bossa = (newthing.match(/bossa/g) || []).length
             var rap = (newthing.match(/rap/g) || []).length
       

             console.log("Funk Occurrences: " + funk)
             console.log("Indie Occurrences: " +indie)
             console.log("Pop Occurrences: " +pop)
             console.log("Rock Occurrences: " +rock)
             console.log("Metal Occurrences: " +metal)
             console.log("Country Occurrences: " +country)
             console.log("Jazz Occurrences: " +jazz)
             console.log("Samba Occurrences: " +samba)
             console.log("Bossa Occurrences: " +bossa)
             console.log("Rap Occurrences: " +rap)

            const popularity = parseInt(response.data.items[0].popularity) +  parseInt(response.data.items[1].popularity) +  parseInt(response.data.items[2].popularity) +  parseInt(response.data.items[3].popularity) +  parseInt(response.data.items[4].popularity)
            const popularityAvg = popularity/5
            
            const finalPop = parseInt(popularityAvg)
            console.log(finalPop)

                 setTopArtist1(response.data.items[0].name)
                 setTopArtist2(response.data.items[1].name)
                 setTopArtist3(response.data.items[2].name)

                 setTopArtist1Src(response.data.items[0].images[0].url)
                 setTopArtist2Src(response.data.items[1].images[0].url)
                 setTopArtist3Src(response.data.items[2].images[0].url)
                 setHasArtists(true)



                 if(funk > 4){
                  setGenre("Brazilian, probably")
                 setGenreDescription("Your current favorite musical genre is Brazilian Funk!")
                 setMidi(true)
                 setRoomColor("blue")
                 }             
                 if(indie > 4){
                 setGenre("an Indiehead")
                 setGenreDescription("Your current favorite musical genre is Indie/Alternative! You prefer to listen to more raw and personal songs.")
                
                 setTele(true)
                 setPoster(indie_poster)
                setRoomColor("violet") 
                }
                 if(pop > 4){
                  setGenre("a Pop fan")
                  setGenreDescription("Your current favorite musical genre is Pop! You are an energetic person and yadda yadda yadda.")
                 
                  setAcoustic(true)
                  }
                  if(metal > 4){
                    setGenre("a Metalhead")
                    setGenreDescription("Your current favorite musical genre is Metal! You like heavy music etc etc.")
                   
                    setElectric(true)
                    }





 if(finalPop > 10  && finalPop < 30){
  console.log("you are underground cause you shit is " + finalPop)
 setFollowers("a True Underground Listener")
setFollowersDescription("You like to get on to the music before everyone else, but that doesn't stop you from appreciating more popular music!")
}

if(finalPop > 30  && finalPop < 60){
  console.log("you are moderately underground cause you shit is " + finalPop)
  setFollowers("a Moderately Underground Listener")
  setFollowersDescription("You like to get on to the music before everyone else, but that doesn't stop you from appreciating more popular music!")
}

if(finalPop > 60){
  console.log("you are mainstream cause you shit is " + finalPop)
  setFollowers("a Mainstream Listener")
  setFollowersDescription("You know that popular music is popular for a reason! You like to be up to date and keep up with the current trends of music.")
}





 
            }
            )


            axios
            .get(
                'https://api.spotify.com/v1/me/top/tracks', {
                    params: { limit: 10, offset: 0 },
                   
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {

 const mostYear = response.data.items[0].album.release_date[0] + response.data.items[0].album.release_date[1]  + response.data.items[0].album.release_date[2]  + response.data.items[0].album.release_date[3]                 
 const mostYear2 = response.data.items[1].album.release_date[0] + response.data.items[1].album.release_date[1]  + response.data.items[1].album.release_date[2]  + response.data.items[1].album.release_date[3]                 

const avg = parseInt(mostYear) + parseInt(mostYear2);
const finalAvg = avg/2


console.log(finalAvg)

setTopAlbum1Src(response.data.items[0].album.images[0].url)
setTopAlbum2Src(response.data.items[1].album.images[0].url)
setTopAlbum3Src(response.data.items[2].album.images[0].url)
setTopAlbum4Src(response.data.items[3].album.images[0].url)

setYearAvg(parseInt(finalAvg))

if(finalAvg > 2010){
setEra("Modern Listener")
setEraDescription("Which means you like to discover new artists and keep up with what they are releasing at all times... ")
}
if(finalAvg > 1990 && finalAvg < 2010){
  setEra("Nostalgic Listener")
  setEraDescription("It means whenever you are looking for good music, you love to go back a few years and remember the simpler times...")
  }
  if(finalAvg > 1970 && finalAvg < 1990){
    setEra("Appreciator of the Classics")
    setEraDescription("")
    }
                }
                )
    



  }, [Cookies.get('spotifyAuthToken')])

  return (
    <div className='app'>
 
      {Cookies.get('spotifyAuthToken') ? (
        <SpotifyApiContext.Provider value={token}>
          <div id="section1" class="section1">
       
{hasToken && <h1 class="animate__animated animate__flipInX" style={{color: "white"}}>Hello {user}!</h1>}
<p></p> <center>
{hasToken && <img class="userPic rounded  animate__animated animate__flipInX" src={pic}/>}
 {hasToken && <div style={{width: "45vw"}}> <p style={{userSelect: "none", marginTop: "2vw" ,cursor: "default", fontSize: "2vw", color: 'white'}}>Before we design your own custom room, let's take a look at some cool information we could find by looking at your profile for inspiration!</p></div>}     


 <Link onClick={() => setAnimation("itemPic rounded  animate__animated animate__flipInX")} to="section2" style={{fontSize: "5vw"}} class="arrow animate__animated animate__fadeIn animate__infinite">⌄</Link>   
       </center></div>

       <div id="section2" class="section2">
       <center>

<div class="row">
  <center>
{hasArtists && <div style={{width: "55vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}> Some of your favorite artists this month included: {topArtist1}, {topArtist2} and {topArtist3}!</p></div>}
<div class="idk d-flex">
  <center>
{hasArtists && <img class={animation} src={topArtist1Src}/>}
{hasArtists && <img class={animation} src={topArtist2Src}/>}
{hasArtists && <img class={animation} src={topArtist3Src}/>}
</center></div>
<div style={{width: "40vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}>You sure have great taste in music...</p></div>

<div style={{width: "42vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}>But now let's try to look closer and define your personality based on what you've been listening to!</p>
</div></center>
</div>


<Link onClick={() => setAnimation("itemPic rounded  animate__animated animate__flipInY")} to="section3" style={{fontSize: "5vw"}} class="arrow animate__animated animate__fadeIn animate__infinite">⌄</Link>      
  </center>  </div>
 
        <div id="section3" class="section3">
        <center>
        <div class="row">
  <center>
{hasArtists && <p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}> You are a {era}!</p>}
<div style={{gap: "30%"}} class="idk d-flex">
{hasArtists && <img class={animation} src={topAlbum1Src}/>}
{hasArtists && <img class={animation} src={topAlbum2Src}/>}

</div>
<div style={{width: "40vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}>The average year of release for your favorite songs is {yearAvg} </p>
</div>
<div style={{width: "40vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}>{eraDescription}</p></div>
</center>
</div>

        </center>
        <Link onClick={() => setAnimation("itemPic rounded  animate__animated animate__flipInX")} to="section4" style={{fontSize: "5vw"}} class="arrow animate__animated animate__fadeIn animate__infinite">⌄</Link>      
  
 </div>


 <div id="section4" class="section4">
        <center>
        <div class="row">
  <center>
{hasArtists && <p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}> You are {genre}!</p>}
<div style={{gap: "30%"}} class="idk d-flex">
{hasArtists && <img class={animation} src={topAlbum1Src}/>}
{hasArtists && <img class={animation} src={topAlbum2Src}/>}

</div>
<div style={{width: "40vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}>{genreDescription}</p></div>
</center>
</div>

        </center>
        <Link to="section5" style={{fontSize: "5vw"}} class="arrow animate__animated animate__fadeIn animate__infinite">⌄</Link>      
  
 </div>



 <div id="section5" class="section5">
        <center>
        <div class="row">
  <center>
{hasArtists && <p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}> You are {followers}!</p>}
<div style={{gap: "30%"}} class="idk d-flex">
{hasArtists && <img class={animation} src={topAlbum1Src}/>}
{hasArtists && <img class={animation} src={topAlbum2Src}/>}

</div>
<div style={{width: "40vw"}}><p style={{userSelect: "none", marginTop: "2vw" ,cursor: "pointer", fontSize: "2vw", color: 'white'}}>{followersDescription}</p></div>
</center>
</div>

        </center>
        <Link to="section6" style={{fontSize: "5vw"}} class="arrow animate__animated animate__fadeIn animate__infinite">⌄</Link>      
  
 </div>



     <div id="section6" class="section3">
        <button id="floatingBtn" class="dl"><a class="fa fa-download"></a><p></p>Download</button>
        <button id="floatingBtn" class="twt"><a class="fa fa-twitter"></a><p></p>Tweet it</button>
        <center>
          <div class="finalContainer">
        <Canvas  shadows
      shadowMap >

   
<PerspectiveCamera
makeDefault
position={[2, 22, 80]}
rotation={[0,0, 0]}
fov={25}


/>
<ambientLight

castShadow
intensity={0.2}/>
<pointLight color={roomColor} intensity={0.8} position={[-2, 25, 8]} castShadow shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
         />
      <primitive
      rotation={[0, 0,0]}
        object={gltf.scene}
        position={[-10, -12, 0]}
        scale={[5, 5, 4]}
        castShadow
        receiveShadow
      />

      {acoustic && <primitive
          object={a_g.scene}
          scale={[0.07, 0.07, 0.09]}
          position={[-14, -8.6, 5]}
          rotation={[-0.6,0.8,0.8]}
          castShadow
        receiveShadow
      />}

 {tele && <primitive
          object={f_t.scene}
          scale={[0.25, 0.25, 0.25]}
          position={[-4.2, -5.7, 0]}
          rotation={[1, 0,0.0]}
          castShadow
          receiveShadow
      />}
{electric && <primitive
          object={g_l.scene}
          scale={[0.9, 0.9, 0.9]}
          position={[-18, 0, 0]}
          rotation={[1.5, 0,0.0]}
          castShadow
          receiveShadow
      />}
     {midi &&  <primitive
          object={m_k.scene}
          scale={[7, 6,6]}
          position={[-10, -6.3, -1]}
          rotation={[0.5, 0,0.0]}
          castShadow
          receiveShadow
      />}


<primitive
          object={h_w.scene}
          scale={[0.05, 0.05,0.05]}
          position={[-13, 0.2, -1.5]}
          rotation={[0, 0,0.0]}
          castShadow
          receiveShadow
      />
<pointLight color={"red"} intensity={0.3} position={[-13, 4.2, -1.5]} castShadow shadow-mapSize-height={1024}/>
 


      <RecordPlayer></RecordPlayer>


<LavaLamp></LavaLamp>





<VinylRecord></VinylRecord>



 <mesh
 scale={[0.020,0.020,0.020]}
position={[-5.1,4.4,-1.99]}

 >
      <sphereBufferGeometry args={[5, 24, 24]} />
      <meshStandardMaterial color={"black"} />
    </mesh>
    <mesh
 scale={[0.020,0.020,0.020]}
position={[-8.9,4.4,-1.99]}

 >
      <sphereBufferGeometry args={[5, 24, 24]} />
      <meshStandardMaterial color={"black"} />
    </mesh>







       <mesh
       position={[-14, -2, -2]}
       scale={[4,4,0.02]}
       
       >
    <boxGeometry />
    <meshStandardMaterial castShadow
          receiveShadow map={colorMap} />
  </mesh>
  <mesh
       position={[-7, 2, -2]}
       scale={[4,5,0.03]}>
    <boxGeometry />
    <meshStandardMaterial map={colorMap2} />
  </mesh>

  <mesh
       position={[2, 0, -1.8]}
       scale={[4,6,0.03]}>
    <boxGeometry />
    <meshStandardMaterial map={currentPoster} />
  </mesh>



  <mesh
   castShadow
   receiveShadow
       position={[state_one, 0.6, 9]}
       scale={[1.5,1.5,0.2]}
         onPointerOver={() => setState_one(-16.8)}
    onPointerOut={() => setState_one(-17)}>
    <boxGeometry />
    <meshStandardMaterial castShadow map={colorMap2_1}/>
  </mesh>
  <mesh
   castShadow
   receiveShadow
       position={[state_two, 0.6, 10]}
       scale={[1.5,1.5,0.2]}
       onPointerOver={() => setState_two(-16.8)}
    onPointerOut={() => setState_two(-17)}>
       
    <boxGeometry/>
    <meshStandardMaterial castShadow map={colorMap3}/>
  </mesh>

  <mesh
       position={[state_three, 0.6, 11]}
       scale={[1.5,1.5,0.2]}
       onPointerOver={() => setState_three(-16.8)}
    onPointerOut={() => setState_three(-17)}>
       
    <boxGeometry />
    <meshStandardMaterial castShadow map={colorMap4}/>
  </mesh>

  <mesh
       position={[state_four, 0.6, 12]}
       scale={[1.5,1.5,0.2]}
       onPointerOver={() => setState_four(-16.8)}
    onPointerOut={() => setState_four(-17)}
    receiveShadow={true}
    castShadow={true}
    >
       
    <boxGeometry  />
    <meshStandardMaterial castShadow map={colorMap5}/>
  </mesh>




  
            <OrbitControls
             minAzimuthAngle={-Math.PI / 12}
             maxAzimuthAngle={Math.PI / 8}
             minPolarAngle={Math.PI / 8}
             maxPolarAngle={Math.PI - Math.PI / 2}
             enablePan={true}
          enableZoom={true}
            />
</Canvas>
</div>



           </center>  
          
        </div>
        </SpotifyApiContext.Provider>
      ) : (
        // Display the login page
        <SpotifyAuth
        btnClassName="spotify"

        redirectUri='http://localhost:3000/Room'
        clientID='2ec7c3d24a3441cb85cad25e9ac39c15'
          scopes={[Scopes.userReadPrivate, 'user-read-email', Scopes.userLibraryRead, Scopes.userTopRead, Scopes.userReadRecentlyPlayed]} // either style will work
          onAccessToken={(token) => setToken(token)}
        />
      )}
    </div>
  )
}  


const Login = () => {
  const gltf = useLoader(GLTFLoader, lilcube)
  const a_g = useLoader(GLTFLoader, acoustic_guitar)
  const e_g = useLoader(GLTFLoader, fender_telecaster)
  const aq = useLoader(GLTFLoader, aquarium)

  gltf.castShadow = true;
  gltf.scene.castShadow = true;



  const [token, setToken] = React.useState(Cookies.get("spotifyAuthToken"))
   const [color, setColor] = React.useState(["yellow", "red", "green", "blue", "pink", "white", "orange", "lightBlue", "lightGreen", "royalBlue", "yellow", "red", "green", "blue", "pink", "white", "orange", "lightBlue", "lightGreen", "royalBlue", "gray", "violet"])
   const [current, setCurrent] = React.useState(0)
  
return(
<>
<div class="idk3">
<div class="loginRoom">


<Canvas>
<PerspectiveCamera
makeDefault
position={[-60, 10, 105]}
rotation={[0,0, 0]}
fov={25}


/>
<ambientLight intensity={0.2}/>
      <pointLight color={color[current]} intensity={1.2} position={[-2, 25, 8]} castShadow />
      <primitive
      rotation={[0, 12,0]}
       onClick={(e) => setCurrent(current + 1)}
        object={gltf.scene}
        position={[-10, -5-7, 0]}
        scale={[5, 5, 4]}
        castShadow
        shadow
      />

      <primitive
          object={a_g.scene}
          scale={[0.07, 0.07, 0.09]}
          position={[-18, -8.8, 1]}
          rotation={[-0.3, 0.4,-0.0]}
          castShadow
        shadow
      />
   

            <OrbitControls
             minAzimuthAngle={-Math.PI / 4}
             maxAzimuthAngle={Math.PI / 4}
             minPolarAngle={Math.PI / 4}
             maxPolarAngle={Math.PI - Math.PI / 2}
             enablePan={true}
          enableZoom={true}
            />
</Canvas>



</div>
<div class="idk2">
  <a href="https://roomify.github.io" class="logo2">Roomify</a>
  <p class="text">Get a custom bedroom based on your favorite music.</p>
  <p class="text">Save it as an image, gif or 3d object and share it wherever you want!</p>
  <button class="roundBtn"><a href="https://twitter.com/polaraminebitch" class="fa fa-twitter"></a></button>
  
  </div><SpotifyAuth
  btnClassName="spotify"
  logoClassName="spotifyLogo"
  redirectUri='http://localhost:3000/Room'
  clientID='2ec7c3d24a3441cb85cad25e9ac39c15'
    scopes={[Scopes.userReadPrivate, 'user-read-email', Scopes.userLibraryRead, Scopes.userTopRead, Scopes.userReadRecentlyPlayed]} // either style will work
    onAccessToken={(token) => setToken(token)}
  />
  </div>
<footer class="footer bg-dark">sdaf</footer>
  </>

)
}
function App() {
  return (
    <Router>
<html>

</html>



<Routes>
  <Route path="/" element={<Login/>}/>
  <Route path="/Room" element={<Room/>}/>
</Routes>
</Router>
  );
}

export default App;
